import Loadable from 'react-loadable';
import React from 'react';
import NetError from '../../components/NetError';

const LoadableComponent = Loadable({
  loader: () => import('../SignContainer/Container'),
  loading: ({ error }) => {
    if (error) {
      return <NetError />;
    }
    
    return null
  },
});

export default LoadableComponent;
