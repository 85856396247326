import InviteCode from './container/GetInviteCodeContainer';
import QRCode from './container/CreateQRCodeContainer';
import NotSupport from './container/NotSupportContainer';
import UseMiniApp from './container/UseMiniAppContainer';
import AuthThirdMiniApp from './container/AuthThirdMiniAppContainer';
import Recruit from './container/RecruitContainer';
import Sign from './container/SignContainer';

export default [{
  path: '/options',
  exact: true,
  component: UseMiniApp,
  title: '珠宝壹佰'
}, {
  path: '/display',
  exact: true,
  component: UseMiniApp,
  title: '珠宝壹佰'
}, {
  path: '/orderList',
  exact: true,
  component: UseMiniApp,
  title: '珠宝壹佰'
}, {
  path: '/orderConfirm',
  exact: true,
  component: UseMiniApp,
  title: '珠宝壹佰'
}, {
  path: '/itemList',
  exact: true,
  component: UseMiniApp,
  title: '珠宝壹佰'
}, {
  path: '/orderDetail/:id',
  exact: true,
  component: UseMiniApp,
  title: '珠宝壹佰'
}, {
  path: '/display/:id',
  exact: true,
  component: UseMiniApp,
  title: '珠宝壹佰'
}, {
  path: '/manageAddress',
  exact: true,
  component: UseMiniApp,
  title: '珠宝壹佰'
}, {
  path: '/editAddress/:id',
  exact: true,
  component: UseMiniApp,
  title: '珠宝壹佰'
}, {
  path: '/cartList',
  exact: true,
  component: UseMiniApp,
  title: '珠宝壹佰'
}, {
  path: '/getInviteCode',
  exact: true,
  component: InviteCode,
  title: '生成邀请码'
}, {
  path: '/createQRCode',
  exact: true,
  component: QRCode,
  title: '生成小程序码'
}, {
  path: '/notSupportMiniapp',
  exact: true,
  component: NotSupport,
  title: '您的微信不支持小程序'
}, {
  path: '/useMiniapp',
  exact: true,
  component: UseMiniApp,
  title: '珠宝壹佰'
}, {
  path: '/authThirdMiniapp',
  exact: true,
  component: AuthThirdMiniApp,
  title: '授权珠宝壹佰小程序定制平台'
}, {
  path: '/recruit',
  exact: true,
  component: Recruit,
  title: '珠宝新零售微商城平台'
}, {
  path: '/sign',
  exact: true,
  component: Sign,
  title: '智秒数服'
}];
